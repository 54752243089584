<template>
<div class="infoOpen">
    <div class="mode-banner">
      <img src="@/assets/info.jpg" alt="">
    </div>
     <div class="sub-menu">
       <div class="layer-wrapper">
         <div class="wrapper">
           <div class="title">
              <span>信息公开</span>
           </div>
         </div>
       </div>
     </div>
     <div class="layer-wrapper">
        <div class="wrapper">
            <div class="current-menu">信息公开</div>
        </div>
     </div>
     <div class="layer-wrapper">
        <div class="wrapper">
          <div class="news-list">
              <div class="item" v-for="(item,index) in list" :key="index" @click="openClick(item.id)">
                  <div class="date">
                    <div class="day">{{intercept(item.dateDay,8,10)}}</div>
                    <div class="year">{{intercept(item.dateDay,0,7)}}</div>
                  </div>
                  <div class="con">
                     <span class="descTitle">{{item.title}}</span>
                     <div class="desc">{{item.synopsis}}</div>
                     <span class="detail">详细></span>
                  </div>
              </div>
          </div>
        </div>
     </div>
    <div class="page-pagination">
      <el-pagination
          layout="total, prev, pager, next"
          :total="pageInfos.total"
          :currentPage="pageInfos.page"
          :page-size="pageInfos.pageSize"
          @current-change="handleCurrentChange"
      />
    </div>
</div>
</template>

<script setup>
import {reactive,ref} from "vue";
import {getNewsPage} from "../../api";
import router from "@/router/index";
const pageInfos=reactive({
  page:1,
  pageSize:10,
  total:0
})
const list=ref([])
// 改变当前页码事件
const handleCurrentChange = (val) => {
  pageInfos.page = val;
  dataNewsPage()
};
function openClick(id){
  router.push({
    path: "infoOpen/detail",
    query:{
      id:id
    }
  })
}
function dataNewsPage(){
  getNewsPage({page:pageInfos.page,size:pageInfos.pageSize}).then(res=>{
    if(res.code==1){
      pageInfos.pageSize = res.data.size
      pageInfos.total = res.data.total
      list.value=res.data.records;
    }
  })
}
function intercept(field,start,end){
  return field.substring(start, end)
}
dataNewsPage()
</script>

<style lang="scss" scoped>
.infoOpen{
  height: 100%;
  z-index: 1;
  max-width: 1920px;
  margin: 0 auto;
  .mode-banner{
    width: 100%;
    //height: 365px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .sub-menu{
    background-color: #f8f8f8;
    height: 45px;
    padding-bottom: 15px;
  }
}
.layer-wrapper{
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 150px;
  .wrapper{
    width: 100%;
    box-sizing: border-box;
    .title{
      float: left;
      min-width: 162px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      position: relative;
      top: -15px;
      box-sizing: border-box;
      span{
        display: block;
        position: relative;
        z-index: 2;
        background-color: #FFDA00;
        color: #fff;
        font-size: 24px;
        padding: 0 10px;
      }
    }
    .current-menu{
      font-size: 32px;
      line-height: 77px;
      border-bottom: 1px solid #eeeeee;
      position: relative;
    }
  }
}
.news-list .item{
  display: block;
  padding: 20px 0;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  .date{
    float: left;
    margin-right: 30px;
    width: 89px;
    height: 89px;
    background-color: #d5d5d5;
    text-align: center;
    .day{
      height: 55px;
      line-height: 55px;
      color: #fff;
      font-size: 32px;
    }
    .year{
      margin: 0 1px;
      line-height: 33px;
      font-size: 16px;
      color: #333;
      background-color: #fff;
    }
  }
  .con{
    float: none;
    overflow: hidden;
    .descTitle{
      padding-top: 6px;
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
    .desc{
      font-size: 14px;
      line-height: 24px;
      color: #999;
      max-height: 48px;
      overflow: hidden;
      margin-top: 10px;
    }
    .detail{
      font-size: 14px;
      color: #999;
      line-height: 1;
      margin-top: 10px;
      display: inline-block;
    }
  }
}
.page-pagination {
  width: 100%;
  display: flex;
  justify-content:center;
  margin: 50px 0;
  ::v-deep .is-first,
  ::v-deep .is-last,
  ::v-deep .number,
  ::v-deep .more {
    background-color: #fff;
  }
  ::v-deep .is-active {
    background-color: #FFDA00 !important;
    color: #fff;
  }
  .demo-pagination-block + .demo-pagination-block {
    margin-top: 10px;
  }
  .demo-pagination-block .demonstration {
    margin-bottom: 16px;
  }
  ::v-deep .el-pagination {
    display: flex;
    flex-wrap: wrap;
  }
}
.item:hover{
  .descTitle{
    color: #FFDA00;
  }
  .date{
    background-color: #FFDA00;
  }
}
</style>
